<style scoped>
.v-btn:not(.v-btn--round).v-size--default {height: 40px;min-width: 64px;padding: 0 16px;}


</style>

<template>
  <div>
   <v-flex class="pa-2">
    <v-container fluid>
      <v-layout row wrap align-start justify-start>
        <v-flex xs12 sm12 md12 lg12 xl12 class="pa-0">
          <v-card class="a-box">
            <v-row class="ma-0" width="fit-content">
                <v-col class="" cols="6" sm="2" xs="12">
                <div>
                  <v-select height="30" dense :items="symbolsList" v-model="instrument"  outlined hide-details label="Instrument Name" :menu-props="{ bottom: true, offsetY: true }"></v-select>
                </div>
              </v-col>
               <v-col class="" cols="6" sm="2" xs="12">
              <div>
                  <!-- <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field outlined dense disabled v-model="selectdate" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" hide-details  label="Select Date" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="selectdate" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.menu.save(selectdate)">OK</v-btn>
                    </v-date-picker>
                  </v-menu> -->
                  <v-text-field v-model="selectdate" label="Date" disabled dense hide-details outlined ></v-text-field>
                </div>
              </v-col>
                <v-col class="" cols="6" sm="2" xs="12">
              <v-select v-model="expirydate" :items="expiryList" dense
                      outlined hide-details label="​Expiry Date" :menu-props="{ bottom: true, offsetY: true }"></v-select>
              </v-col>
                <v-col class="" cols="6" sm="2" xs="12">
               <v-btn color="primary" dense>Go</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-flex>
    <v-flex xs12 class="pl-2 pr-2 pb-0">
      <v-container fluid class="chartfullview1">
        <v-layout row wrap align-start justify-start>
          <v-flex xs12 sm12 md12 lg12 xl12 class="pa-0">
            <v-card class="a-box pa-2">
                <div class="text-center font-weight-bold openSans mt-2">Cumilative OI - {{instrument}} {{expirydate}}</div>
              <v-chart class="chart" autoresize :options="cumilativeoi" />
            </v-card>
          </v-flex>
        </v-layout
      ></v-container>
    </v-flex>
  </div>
</template>
<script>
import { mapState } from "vuex"
import VChart, {} from "vue-echarts";
import "@/plugins/echart";

export default {
  components: {
    VChart,
  },
  
  computed: mapState(['global']),
  data() {
    return {
      selectdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toLocaleDateString().substr(0, 10),
      menu: false,
      instrument: "BANKNIFTY",
      expirydate: '18NOV21',
      cumilativeoi: {
        tooltip: { trigger: "axis",},
        legend: {data: ["Combined", "VWAP"], bottom: "1%",},
        grid: {left: "1%",right: "1%",bottom: 60,containLabel: true,},
        toolbox: {
          feature: {
            //dataView: { show: true, readOnly: false },
            magicType: { show: true, title: '' , type: ["line", "bar",'pie'] },
            restore: { show: true, title: 'Restore' },
            saveAsImage: { show: true, title: 'Save' },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisPointer: {type: "shadow",shadowColor: "#E6E5E4",},
          data: ["10.1","11.1","12.1","13.1","14.1","15.1","16.3","17.3","18.2","18.9","19.5","20.5", "21.7","22.9",],
        },
        yAxis: {type: "value",},
        series: [
          {name: "Combined",type: "line",smooth: true, stack: "",data: [12.0, 13.2, 1.01, 13.4, 9.0, 23.0, 21.0, 45.9, 78.9, 12.0, 67.0,23.8, 89.2, 13.8,],},
          {name: "VWAP",type: "line",stack: "",smooth: true,
          data: [12.0, 13.2, 17.01, 13.4, 19.0, 23.0, 29.0, 6.9, 81.9, 12.0, 67.0,78.8, 19.2, 17.8, ],
      },
        ],
      },
    };
  },
  methods:{
      getCurrentPrice (value) {
      if(value)
      return this.strickPriceList.reduce((prev, curr) => Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
    },  
    async initialFunctions(){
      await this.$store.dispatch('symbols/getSymbolsList');
      await this.$store.dispatch('symbols/getSymbolsDetails', this.instrument);
     
    }
  },
  computed: {
      ...mapState('symbols',['symbolsList','expiryList','currentExpiryDate']),
      //  expirydate: {
      //   get:function() {return this.currentExpiryDate},
      //   set:function(val) {}
      // },
    },
  watch: {

  },

  mounted() {
    var isDark = localStorage.getItem("dark_theme");
    var theme = (isDark == 'true')?'dark':'light';
    this.initialFunctions()
  
}
};
</script>

